import { Card, CardHeader, Grid, GridItem, Skeleton } from "@chakra-ui/react";

export default function TodoGroupSkeleton() {
  return (
    <Card w="100%">
      <CardHeader>
        <Grid my="2" templateColumns={"1fr 1fr 1fr"}>
          <GridItem>
            <Skeleton h="20px" w="20px" />
          </GridItem>
          <GridItem display={"flex"} justifyContent={"center"}>
            <Skeleton h="20px" w="100px" />
          </GridItem>
          <GridItem></GridItem>
        </Grid>
      </CardHeader>
    </Card>
  );
}
