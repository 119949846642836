import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Divider,
  Fade,
  Flex,
  Grid,
  GridItem,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Progress,
  ScaleFade,
  Skeleton,
  Text,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import {
  FaBars,
  FaBurn,
  FaCheckCircle,
  FaCheckDouble,
  FaPlus,
  FaRegCircle,
  FaTimes,
  FaTrash,
} from "react-icons/fa";
import React, { useEffect, useState } from "react";
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
} from "@hello-pangea/dnd";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  IAddTaskError,
  IAddTaskSuccess,
  IAddTaskVariables,
  IDeleteTaskError,
  IDeleteTaskVariables,
  IUpdateTaskError,
  IUpdateTaskSuccess,
  IUpdateTaskVariables,
  addTask,
  deleteTask,
  getSubTasks,
  getTask,
  updateTask,
} from "../routes/api";
import { useFieldArray, useForm } from "react-hook-form";
import { ITask } from "../routes/Todolist";
import { v4 as uuidv4 } from "uuid";
import TodoModalSkeleton from "./TodoModalSkeleton";

interface TodoModalProps {
  isOpen: boolean;
  onClose: () => void;
  currentTaskPk?: number | null;
  currentTodoGroupPk?: number | null;
  tasks: ITask[];
}
interface ISubTask {
  uuid: string; // 고유 식별자 추가
  pk: number | null;
  completed: boolean;
  text: string;
  order: number;
}

export interface ITaskDetail {
  pk: number;
  order: number;
  text: string;
  completed: boolean;
  condition: string;
  subtasks: ISubTask[];
}

interface IFormValues {
  text: string;
  condition: string;
  subtasks: ISubTask[];
}

interface IChangedSubTasks {
  added: ISubTask[];
  updated: ISubTask[];
  deleted: number[];
}

export default function TodoModal({
  isOpen,
  onClose,
  currentTaskPk,
  currentTodoGroupPk,
  tasks,
}: TodoModalProps) {
  const [isAllCompleted, setIsAllCompleted] = useState(false);
  const [changedSubTasks, setChangedSubTasks] = useState<IChangedSubTasks>({
    added: [],
    updated: [],
    deleted: [],
  });
  const [count, setCount] = useState("");
  const [isCounting, setIsCounting] = useState(false);
  const { isLoading: isTaskLoading, data: currentTask } = useQuery<
    ITaskDetail,
    Error
  >({
    queryKey: ["task", currentTaskPk],
    queryFn: getTask,
    enabled: currentTaskPk !== null,
  });
  const { isLoading: isSubtasksLoading, data: subtasksData } = useQuery<
    ISubTask[]
  >({
    queryKey: ["subtasks", currentTaskPk],
    queryFn: getSubTasks,
    enabled: currentTaskPk !== null,
  });
  const { register, handleSubmit, control, reset, watch } =
    useForm<IFormValues>({
      defaultValues: {
        text: currentTask?.text || "",
        condition: currentTask?.condition || "",
        subtasks: subtasksData?.length ? subtasksData : [],
      },
    });
  const { fields, append, remove, update, replace } = useFieldArray({
    control,
    name: "subtasks",
  });
  const {
    isOpen: isDeleteAlertOpen,
    onOpen: onDeleteAlertOpen,
    onClose: onDeleteAlertClose,
  } = useDisclosure();
  const {
    isOpen: isCancelAlertOpen,
    onOpen: onCancelAlertOpen,
    onClose: onCancelAlertClose,
  } = useDisclosure();
  const cancelRef = React.useRef(null);
  const queryClient = useQueryClient();
  const addTaskMutation = useMutation<
    IAddTaskSuccess,
    IAddTaskError,
    IAddTaskVariables
  >({
    mutationFn: addTask,
    onSuccess: () => {
      onClose();
      reset();
    },
    onError: (error) => console.log(error),
  });

  const updateTaskMutation = useMutation<
    IUpdateTaskSuccess,
    IUpdateTaskError,
    IUpdateTaskVariables
  >({
    mutationFn: updateTask,
    onError: (error) => console.log(error),
  });

  const deleteTaskMutation = useMutation<
    any,
    IDeleteTaskError,
    IDeleteTaskVariables
  >({
    mutationFn: deleteTask,
    onSuccess: (data) =>
      queryClient
        .refetchQueries({ queryKey: ["tasks", currentTodoGroupPk] })
        .then(() => {
          onDeleteAlertClose();
          onClose();
        }),
    onError: (error) => console.log(error),
  });

  useEffect(() => {
    if (currentTask && currentTaskPk && subtasksData) {
      reset({
        text: currentTask.text,
        condition: currentTask.condition,
        subtasks: subtasksData?.length ? subtasksData : [],
      });
    } else {
      reset({
        text: "",
        condition: "",
        subtasks: [],
      });
    }
  }, [currentTask, currentTaskPk, reset, replace, subtasksData]);

  const [focusedSubTaskIndex, setFocusedSubTaskIndex] = useState<number | null>(
    null
  );

  const calculateSubTaskProgress = () => {
    const subTasks = watch("subtasks");
    const totalSubTasks = subTasks.length;
    const completedSubTasks = subTasks.filter(
      (subTask) => subTask.completed
    ).length;
    return totalSubTasks === 0
      ? 0
      : Math.round((completedSubTasks / totalSubTasks) * 100);
  };

  const subTaskProgress = calculateSubTaskProgress();

  const addSubTask = () => {
    const newSubTask = {
      pk: null,
      order: fields.length + 1,
      text: "",
      completed: false,
      uuid: uuidv4(),
    };
    append(newSubTask);
    setChangedSubTasks((prev) => ({
      ...prev,
      added: [...prev.added, newSubTask],
    }));
  };

  const deleteSubTask = (index: number) => {
    const subTaskToDelete = fields[index];
    if (subTaskToDelete.pk !== null) {
      setChangedSubTasks((prev) => ({
        ...prev,
        deleted: [...prev.deleted, subTaskToDelete.pk as number],
        updated: prev.updated.filter((task) => task.pk !== subTaskToDelete.pk), // updated에서 제거
      }));
    } else {
      // 새로 추가된 서브 할 일의 경우 `added` 배열에서 제거
      setChangedSubTasks((prev) => ({
        ...prev,
        added: prev.added.filter((subtask) => {
          return subtask.uuid !== subTaskToDelete.uuid;
        }),
      }));
    }
    remove(index);
  };

  const toggleSubTaskCompletion = (index: number) => {
    const subTaskToUpdate = fields[index];
    const updatedCompleted = !subTaskToUpdate.completed;

    const updatedSubTask = {
      ...subTaskToUpdate,
      completed: updatedCompleted,
    };

    update(index, updatedSubTask);

    const initialSubTask = subtasksData?.find(
      (task) => task.pk === subTaskToUpdate.pk
    );
    if (!initialSubTask) {
      // 서버에 subtask가 없으면, added 상태의 subtask(새로 추가된 subtask) 업데이트
      setChangedSubTasks((prev) => {
        const addedList = prev.added.map((subtask) =>
          subtask.uuid === subTaskToUpdate.uuid ? updatedSubTask : subtask
        );
        return {
          ...prev,
          added: addedList,
        };
      });
    } else {
      // 서버에 subtask가 있으면
      const isTextChanged = initialSubTask.text !== subTaskToUpdate.text;
      const isCompletedChanged = initialSubTask.completed !== updatedCompleted;
      const isOrderChanged = initialSubTask.order !== subTaskToUpdate.order;

      if (isCompletedChanged || isTextChanged || isOrderChanged) {
        // 서버의 데이터와 다를 경우 updated에 추가
        setChangedSubTasks((prev) => {
          const updatedList = prev.updated.filter(
            (task) => task.pk !== subTaskToUpdate.pk
          );
          return {
            ...prev,
            updated: [...updatedList, updatedSubTask],
          };
        });
      } else {
        // 데이터가 다시 원래대로 돌아온 경우 `updated`에서 제거
        setChangedSubTasks((prev) => {
          const updatedList = prev.updated.filter(
            (task) => task.pk !== subTaskToUpdate.pk
          );
          return {
            ...prev,
            updated: updatedList,
          };
        });
      }
    }
  };

  const updateSubTaskValue = (index: number, text: string) => {
    setFocusedSubTaskIndex(null);
    const subTaskToUpdate = fields[index];
    const updatedSubTask = { ...subTaskToUpdate, text };
    update(index, updatedSubTask);

    const initialSubTask = subtasksData?.find(
      (task) => task.pk === subTaskToUpdate.pk
    );

    if (!initialSubTask) {
      // 서버에 subtask가 없으면, added 상태의 subtask(새로 추가된 subtask) 업데이트
      setChangedSubTasks((prevState) => {
        const addedList = prevState.added.map((task) =>
          task.order === subTaskToUpdate.order ? updatedSubTask : task
        );
        return {
          ...prevState,
          added: addedList,
        };
      });
    } else {
      // 서버에 subtask가 있으면
      const isTextChanged = initialSubTask.text !== text;
      const isCompletedChanged =
        initialSubTask.completed !== subTaskToUpdate.completed;
      const isOrderChanged = initialSubTask.order !== subTaskToUpdate.order;

      if (isTextChanged || isCompletedChanged || isOrderChanged) {
        // 서버의 text와 다를 경우 updated에 추가
        setChangedSubTasks((prevState) => {
          const updatedList = prevState.updated.filter(
            (task) => task.pk !== subTaskToUpdate.pk
          );
          return {
            ...prevState,
            updated: [...updatedList, updatedSubTask],
          };
        });
      } else {
        // text 필드가 다시 원래대로 돌아온 경우 `updated`에서 제거
        setChangedSubTasks((prevState) => {
          const updatedList = prevState.updated.filter(
            (task) => task.pk !== subTaskToUpdate.pk
          );
          return {
            ...prevState,
            updated: updatedList,
          };
        });
      }
    }
  };

  const onDragEnd = (result: DropResult) => {
    const { destination, source } = result;
    if (!destination) {
      return;
    }

    const currentSubTasks = watch("subtasks");
    const [movedSubTask] = currentSubTasks.splice(source.index, 1);
    currentSubTasks.splice(destination.index, 0, movedSubTask);

    // 순서(order) 업데이트
    const updatedSubTasks = currentSubTasks.map((subTask, index) => ({
      ...subTask,
      order: index + 1,
    }));

    replace(updatedSubTasks);

    setChangedSubTasks((prevChangedSubTasks) => {
      // 새로 업데이트된 subTasks 가져오기
      const newUpdatedList = updatedSubTasks.filter((task) => task.pk !== null);
      // 기존 added 목록에서 순서 변경
      const newAddedList = prevChangedSubTasks.added.map((prevAddedSubTask) => {
        const newAddedSubTask = updatedSubTasks.find(
          (updatedSubTask) => updatedSubTask.uuid === prevAddedSubTask.uuid
        );
        return newAddedSubTask
          ? { ...prevAddedSubTask, order: newAddedSubTask.order }
          : prevAddedSubTask;
      });

      return {
        ...prevChangedSubTasks,
        updated: [...newUpdatedList],
        added: newAddedList,
      };
    });

    reset({ subtasks: updatedSubTasks });
  };

  const saveButtonRef = React.useRef<HTMLButtonElement>(null);

  const handleKeyDown = async (event: React.KeyboardEvent) => {
    if (event.nativeEvent.isComposing) {
      return;
    }
    if (event.key === "Enter") {
      event.preventDefault();
      const target = event.target as HTMLElement;
      target.blur();
    }
  };

  const onSubmit = async (data: IFormValues) => {
    if (currentTaskPk && currentTodoGroupPk) {
      const task = { text: data.text, condition: data.condition };
      updateTaskMutation.mutate(
        {
          taskPk: currentTaskPk,
          task,
          subtasks: changedSubTasks,
        },
        {
          onSuccess: (data) => {
            queryClient.setQueryData<ITask[]>(
              ["tasks", currentTodoGroupPk],
              (oldData) => {
                if (oldData) {
                  const filteredOldData = oldData.filter(
                    (task) => task.pk !== data.pk
                  );
                  const newTasks = [
                    ...filteredOldData,
                    {
                      pk: data.pk,
                      text: data.text,
                      completed: data.completed,
                      order: data.order,
                      group: data.group,
                    },
                  ];
                  const orderedTasks = newTasks.sort(
                    (a, b) => a.order - b.order
                  );
                  return orderedTasks;
                }
              }
            );

            onClose();
            queryClient
              .refetchQueries({ queryKey: ["task", currentTaskPk] })
              .then(() =>
                queryClient.refetchQueries({
                  queryKey: ["subtasks", currentTaskPk],
                })
              );
          },
        }
      );
    } else if (currentTodoGroupPk && !currentTaskPk) {
      const subtasks = data.subtasks;
      const orderedSubtasks = subtasks.map((subtask, index) => ({
        ...subtask,
        order: index,
      }));
      const currentMaxOrder = tasks.reduce(
        (maxOrder, task) => Math.max(maxOrder, task.order),
        0
      );

      const newTask = {
        text: data.text,
        condition: data.condition,
        order: currentMaxOrder + 1,
      };

      //POST request
      addTaskMutation.mutate(
        {
          groupPk: currentTodoGroupPk,
          task: newTask,
          subtasks: orderedSubtasks,
        },
        {
          onSuccess: (data) => {
            console.log(data);
            queryClient.setQueryData<ITask[]>(
              ["tasks", currentTodoGroupPk],
              (oldData) => {
                if (oldData !== undefined) {
                  return [
                    ...oldData,
                    {
                      pk: data.pk,
                      text: data.text,
                      order: data.order,
                      completed: data.completed,
                      group: data.group,
                    },
                  ];
                }
              }
            );
          },
        }
      );
    }
  };

  const handleDelete = () => {
    if (currentTaskPk) {
      deleteTaskMutation.mutate({ taskPk: currentTaskPk });
    }
  };

  const handleCancel = () => {
    onCancelAlertClose();
    onClose();
  };

  const handleModalClose = () => {
    const { text, condition } = watch();

    let isTaskChanged;
    if (currentTask) {
      isTaskChanged =
        currentTask?.text !== text || currentTask?.condition !== condition;
    } else {
      isTaskChanged = text !== "" || condition !== "";
    }

    const isSubtasksChanged =
      changedSubTasks.added.length > 0 ||
      changedSubTasks.updated.length > 0 ||
      changedSubTasks.deleted.length > 0;

    if (isTaskChanged || isSubtasksChanged) {
      onCancelAlertOpen();
    } else {
      onClose();
    }
  };

  const hanleCountClick = () => {
    setIsCounting(true);
    setCount("5");
    setTimeout(() => setCount("4"), 1000);
    setTimeout(() => setCount("3"), 2000);
    setTimeout(() => setCount("2"), 3000);
    setTimeout(() => setCount("1"), 4000);
    setTimeout(() => setCount("시작!!"), 5000);
    setTimeout(() => {
      setCount("");
      setIsCounting(false);
    }, 8000);
  };

  // useEffect(() => {
  //   console.log(changedSubTasks);
  // }, [changedSubTasks]);

  useEffect(() => {
    const subtasksCompletedCount = fields.filter(
      (subtask) => subtask.completed
    ).length;
    if (fields.length === subtasksCompletedCount) {
      setIsAllCompleted(true);
    } else {
      setIsAllCompleted(false);
    }
  }, [fields]);

  const handleCompleteAllTask = () => {
    const currentSubtasks = watch("subtasks");
    if (!isAllCompleted) {
      // 전체 완료 상태가 아니면
      const updatedSubtasks = currentSubtasks.map((subtask) => {
        return { ...subtask, completed: true };
      });
      replace(updatedSubtasks);

      const newUpdatedSubtasks = updatedSubtasks.filter(
        (subtask) => subtask.pk
      );
      const newAddedSubtasks = updatedSubtasks.filter(
        (subtask) => subtask.uuid
      );

      setChangedSubTasks((prev) => ({
        deleted: [...prev.deleted],
        updated: newUpdatedSubtasks,
        added: newAddedSubtasks,
      }));
    } else {
      // 전체 완료 상태면
      const updatedSubtasks = currentSubtasks.map((subtask) => {
        return { ...subtask, completed: false };
      });
      replace(updatedSubtasks);

      const newUpdatedSubtasks = updatedSubtasks.filter(
        (subtask) => subtask.pk
      );
      const newAddedSubtasks = updatedSubtasks.filter(
        (subtask) => subtask.uuid
      );

      setChangedSubTasks((prev) => ({
        deleted: [...prev.deleted],
        updated: newUpdatedSubtasks,
        added: newAddedSubtasks,
      }));
    }
  };

  return (
    <Modal onClose={handleModalClose} isOpen={isOpen} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <AlertDialog
          isCentered
          isOpen={isCancelAlertOpen}
          leastDestructiveRef={cancelRef}
          onClose={onCancelAlertClose}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                {currentTask?.text} 닫기
              </AlertDialogHeader>
              <AlertDialogBody>저장하지 않고 닫으시겠습니까?</AlertDialogBody>
              <AlertDialogFooter>
                <Button ref={cancelRef} onClick={onCancelAlertClose}>
                  취소
                </Button>
                <Button colorScheme="red" onClick={handleCancel} ml={3}>
                  닫기
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
        {isTaskLoading ? (
          <ModalBody mt="10">
            <TodoModalSkeleton />
          </ModalBody>
        ) : (
          <ModalBody as="form" onSubmit={handleSubmit(onSubmit)} mt="10">
            <VStack>
              <InputGroup>
                <InputLeftElement
                  children={
                    <Flex color="gray.400" justifyContent={"center"}>
                      <Icon as={FaCheckDouble} />
                    </Flex>
                  }
                />
                <Input
                  onKeyDown={handleKeyDown}
                  autoComplete="off"
                  variant={"filled"}
                  placeholder="해낼 일 하나를 적어보세요!"
                  {...register("text", {
                    required: true,
                  })}
                />
              </InputGroup>
              <InputGroup>
                <InputLeftElement
                  children={
                    <Box color="gray.400">
                      <FaBurn />
                    </Box>
                  }
                />
                <Input
                  onKeyDown={handleKeyDown}
                  autoComplete="off"
                  variant={"filled"}
                  placeholder="구체적으로 언제, 어디서 할 건가요?"
                  {...register("condition")}
                />
              </InputGroup>
              {currentTask ? (
                <>
                  <Button
                    isDisabled={isCounting}
                    onClick={hanleCountClick}
                    w="100%"
                    variant={"outline"}
                    colorScheme="teal"
                  >
                    5초 카운트 →
                  </Button>
                  <Heading>{count}</Heading>
                </>
              ) : null}
            </VStack>
            <Divider my="5" />
            <Box>
              <Flex justifyContent={"space-between"} alignItems={"center"}>
                <Text>달성률: {subTaskProgress}%</Text>
                <Text>
                  {subTaskProgress === 0
                    ? "화이팅!"
                    : subTaskProgress === 100
                    ? "해내셨군요!"
                    : "잘하고 있어요!"}
                </Text>
              </Flex>
              <Progress
                my="3"
                color="green.400"
                value={subTaskProgress}
                height="20px"
                sx={{
                  "& > div:first-of-type": {
                    transitionProperty: "width",
                    transitionDuration: "0.5s",
                    transitionTimingFunction: "ease-in-out",
                  },
                }}
              />
            </Box>
            <VStack>
              {isSubtasksLoading ? (
                <>
                  <Skeleton width="100%" h="40px" />
                  <Skeleton width="100%" h="40px" />
                  <Skeleton width="100%" h="40px" />
                  <Skeleton width="100%" h="40px" />
                  <Skeleton width="100%" h="40px" />
                </>
              ) : (
                <DragDropContext onDragEnd={onDragEnd}>
                  <Box w="100%">
                    <Droppable droppableId="droppable-1">
                      {(provided) => (
                        <VStack
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                        >
                          {fields.map((subTask, index) => (
                            <Draggable
                              key={index}
                              draggableId={`draggable-${index}`}
                              index={index}
                            >
                              {(provided) => (
                                <Box
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  w="100%"
                                  key={index}
                                >
                                  <InputGroup>
                                    <InputLeftElement
                                      cursor={"pointer"}
                                      onClick={() =>
                                        toggleSubTaskCompletion(index)
                                      }
                                      children={
                                        <Flex
                                          fontSize="large"
                                          justifyContent={"center"}
                                        >
                                          <Icon
                                            as={
                                              subTask.completed
                                                ? FaCheckCircle
                                                : FaRegCircle
                                            }
                                          />
                                        </Flex>
                                      }
                                    />
                                    <Input
                                      onKeyDown={handleKeyDown}
                                      autoComplete="off"
                                      variant={"filled"}
                                      placeholder="할 일 쪼개기"
                                      {...register(`subtasks.${index}.text`)}
                                      onFocus={() =>
                                        setFocusedSubTaskIndex(index)
                                      }
                                      onBlur={(e) =>
                                        updateSubTaskValue(
                                          index,
                                          e.target.value
                                        )
                                      }
                                    />
                                    <InputRightElement
                                      cursor={"pointer"}
                                      children={
                                        focusedSubTaskIndex === index ? (
                                          <Flex
                                            w="100%"
                                            h="100%"
                                            justifyContent={"center"}
                                            alignItems={"center"}
                                            onMouseDown={() =>
                                              deleteSubTask(index)
                                            }
                                          >
                                            <FaTrash color="red" />
                                          </Flex>
                                        ) : (
                                          <Flex
                                            w="100%"
                                            h="100%"
                                            justifyContent={"center"}
                                            alignItems={"center"}
                                            {...provided.dragHandleProps}
                                          >
                                            <FaBars />
                                          </Flex>
                                        )
                                      }
                                    ></InputRightElement>
                                  </InputGroup>
                                </Box>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </VStack>
                      )}
                    </Droppable>
                  </Box>
                </DragDropContext>
              )}

              <Grid w="100%" gridTemplateColumns={"1fr 1fr 1fr"}>
                <GridItem
                  display={"flex"}
                  alignItems={"center"}
                  fontSize={"18"}
                >
                  <Flex
                    onClick={handleCompleteAllTask}
                    cursor={"pointer"}
                    w="40px"
                    h="100%"
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <ScaleFade initialScale={0.5} in={fields.length > 1}>
                      {isAllCompleted ? <FaCheckCircle /> : <FaRegCircle />}
                    </ScaleFade>
                  </Flex>
                </GridItem>
                <GridItem
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  {(fields?.length ?? 0) >= 30 ? null : (
                    <Button onClick={() => addSubTask()}>
                      {fields.length ? <FaPlus /> : <Text>할 일 쪼개기</Text>}
                    </Button>
                  )}
                </GridItem>
                <GridItem></GridItem>
              </Grid>
            </VStack>
            <Button
              isLoading={
                addTaskMutation.isPending || updateTaskMutation.isPending
              }
              mt="5"
              colorScheme="green"
              w={"100%"}
              type="submit"
              ref={saveButtonRef}
            >
              저장
            </Button>
            {currentTask && (
              <>
                <Button
                  mt="2"
                  colorScheme="red"
                  w={"100%"}
                  onClick={onDeleteAlertOpen}
                >
                  삭제
                </Button>
                <AlertDialog
                  isCentered
                  isOpen={isDeleteAlertOpen}
                  leastDestructiveRef={cancelRef}
                  onClose={onDeleteAlertClose}
                >
                  <AlertDialogOverlay>
                    <AlertDialogContent>
                      <AlertDialogHeader fontSize="lg" fontWeight="bold">
                        {currentTask?.text} 삭제
                      </AlertDialogHeader>
                      <AlertDialogBody>삭제하시겠습니까?</AlertDialogBody>
                      <AlertDialogFooter>
                        <Button ref={cancelRef} onClick={onDeleteAlertClose}>
                          취소
                        </Button>
                        <Button
                          isLoading={deleteTaskMutation.isPending}
                          colorScheme="red"
                          onClick={handleDelete}
                          ml={3}
                        >
                          삭제
                        </Button>
                      </AlertDialogFooter>
                    </AlertDialogContent>
                  </AlertDialogOverlay>
                </AlertDialog>
              </>
            )}
          </ModalBody>
        )}
      </ModalContent>
    </Modal>
  );
}
