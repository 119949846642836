import { Divider, Flex, Skeleton } from "@chakra-ui/react";

export default function TodoModalSkeleton() {
  return (
    <>
      <Skeleton height="35px" />
      <Skeleton mt="2" height="35px" />
      <Skeleton mt="2" height="35px" />
      <Divider my="5" />
      <Flex justifyContent={"space-between"}>
        <Skeleton height="20px" width="50px" />
        <Skeleton height="20px" width="50px" />
      </Flex>
      <Skeleton mt="5" height="20px" />
      <Skeleton mt="5" height="35px" />
      <Skeleton mt="2" height="35px" />
      <Skeleton mt="2" height="35px" />
      <Skeleton mt="2" height="35px" />
      <Skeleton mt="2" height="35px" />
      <Flex justifyContent={"center"}>
        <Skeleton mt="2" height="35px" width="40px" />
      </Flex>
      <Skeleton mt="2" height="35px" />
      <Skeleton mt="2" height="35px" />
    </>
  );
}
