import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useUser from "../lib/useUser";

interface ITermsAgreementProtected {
  children: React.ReactNode;
}

export default function TermsAgreementProtected({
  children,
}: ITermsAgreementProtected) {
  const { isLoggedIn, userLoading, user } = useUser();
  const navigate = useNavigate();
  useEffect(() => {
    if (!userLoading && isLoggedIn) {
      if (user) {
        if (user.is_privacy_and_terms_agreed === false) {
          navigate("/sign-up/social");
        }
      }
    }
  }, [userLoading, isLoggedIn, user, navigate]);
  return <>{children}</>;
}
