import { Button, Heading, Spinner, Text, VStack } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";

import { Link, useNavigate, useParams } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import { verifyEmail } from "./api";
import ProtectedPage from "../components/ProtectedPage";
import SendEmailProtected from "../components/SendEmailProtected";

export default function VerifyEmail() {
  const { token } = useParams();

  const { isLoading, isSuccess, isError } = useQuery({
    queryKey: ["verifyEmail", token],
    queryFn: verifyEmail,
  });
  const navigate = useNavigate();
  useEffect(() => {
    if (!isLoading) {
      if (isSuccess) {
        navigate("/email-verified-success");
      } else if (isError) {
        navigate("/email-verified-failed");
      }
    }
  }, [isLoading, isError, isSuccess]);
  return (
    <SendEmailProtected>
      <VStack
        justifyContent={"center"}
        minHeight="calc(100vh - 128px)"
        w="100%"
      >
        <BeatLoader color="teal" />
      </VStack>
    </SendEmailProtected>
  );
}
