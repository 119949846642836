import { Flex, Skeleton, SkeletonCircle } from "@chakra-ui/react";

export default function TaskListSkeleton() {
  return (
    <Flex my="6" mx="3" justifyContent={"space-between"}>
      <Flex alignItems={"center"}>
        <SkeletonCircle mr="3" size="5" />
        <Skeleton h="20px" w="200px" />
      </Flex>
      <Flex>
        <Skeleton h="20px" w="20px" />
      </Flex>
    </Flex>
  );
}
