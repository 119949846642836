import { Button, Heading, Spinner, Text, VStack } from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { kakaoLogin } from "./api";

export default function KakaoConfirm() {
  const { search } = useLocation();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const hasFetchedRef = useRef(false); // 2번 fetch 방지용

  const confirmLogin = async () => {
    const params = new URLSearchParams(search);
    const code = params.get("code");
    if (code && !hasFetchedRef.current) {
      hasFetchedRef.current = true;
      const status = await kakaoLogin(code);
      if (status === 200) {
        queryClient.refetchQueries({ queryKey: ["me"] });
        navigate("/");
      }
    }
  };
  useEffect(() => {
    confirmLogin();
  }, []);

  return (
    <VStack justifyContent={"center"} minH="calc(100vh - 128px)">
      <Heading>로딩중..</Heading>
      <Text>페이지에서 벗어나지 마세요.</Text>
      <Spinner size="xl" />
    </VStack>
  );
}
