import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Checkbox,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { deleteUser } from "../routes/api";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

export default function DeleteUserCard() {
  const [isChecked, setIsChecked] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef(null);
  const navegate = useNavigate();
  const mutation = useMutation({
    mutationFn: deleteUser,
    onSuccess: () => navegate("/"),
  });

  const onDelete = () => {
    mutation.mutate();
  };

  return (
    <>
      <Flex justifyContent={"center"} w="100%">
        <Text color="red" fontSize={24}>
          계정 삭제
        </Text>
      </Flex>
      <Card mt={3}>
        <CardBody>
          <Stack>
            <Text>이 작업은 영구적이며 되돌릴 수 없습니다.</Text>
            <Text>계정을 삭제한 후에는 아무도 이를 복구할 수 없습니다.</Text>
            <Divider my="3" />
            <Flex justifyContent={"flex-end"}>
              <Checkbox onChange={(e) => setIsChecked(e.target.checked)}>
                계정 삭제 후 되돌릴 수 없음을 이해합니다.
              </Checkbox>
            </Flex>
            <Flex justifyContent={"flex-end"}>
              <Button
                isDisabled={!isChecked}
                mt="3"
                colorScheme="red"
                onClick={onOpen}
              >
                계정 삭제
              </Button>
              <AlertDialog
                isCentered
                isOpen={isOpen}
                leastDestructiveRef={cancelRef}
                onClose={onClose}
              >
                <AlertDialogOverlay>
                  <AlertDialogContent as="form">
                    <AlertDialogHeader fontSize="lg" fontWeight="bold">
                      계정 삭제
                    </AlertDialogHeader>
                    <AlertDialogBody>
                      <Text>정말로 계정을 삭제하시겠습니까?</Text>
                      <Text>계정과 관련된 모든 데이터가 사라집니다.</Text>
                    </AlertDialogBody>
                    <AlertDialogFooter>
                      <Button ref={cancelRef} onClick={onClose}>
                        아니요. 돌려주세요.
                      </Button>
                      <Button
                        type="submit"
                        isLoading={mutation.isPending}
                        colorScheme="red"
                        onClick={onDelete}
                        ml={3}
                      >
                        네. 확신합니다.
                      </Button>
                    </AlertDialogFooter>
                  </AlertDialogContent>
                </AlertDialogOverlay>
              </AlertDialog>
            </Flex>
          </Stack>
        </CardBody>
      </Card>
    </>
  );
}
