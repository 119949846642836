import { Box, Button, Divider, HStack, Text, VStack } from "@chakra-ui/react";
import { FaComment } from "react-icons/fa";

export default function SocialLogin() {
  const kakaoParams = {
    client_id: "9f639e4b351ea6e6d8f76e11edec4527",
    redirect_uri: "https://www.beself.co.kr/social/kakao",
    response_type: "code",
  };
  const params = new URLSearchParams(kakaoParams).toString();
  return (
    <Box w="100%">
      <HStack my={8}>
        <Divider />
        <Text
          color={"gray.500"}
          textTransform={"uppercase"}
          fontSize={"xs"}
          as="b"
        >
          Or
        </Text>
        <Divider />
      </HStack>
      <VStack>
        <Button
          as="a"
          href={`https://kauth.kakao.com/oauth/authorize?${params}`}
          w="100%"
          leftIcon={<FaComment />}
          colorScheme="yellow"
        >
          카카오 →
        </Button>
        <Text textAlign={"center"} fontSize={"12"}>
          *iOS 카카오 로그인 안될 때:
          <br />
          비공개 릴레이를 잠시 껐다가 로그인 하시고 다시 켜주세요!
          <br />
          설정 → 계정 → iCloud → 비공개 릴레이 → 끔
        </Text>
      </VStack>
    </Box>
  );
}
