import {
  Button,
  Card,
  CardBody,
  Container,
  Divider,
  Text,
  VStack,
} from "@chakra-ui/react";
import { FaComment, FaUser, FaUserPlus } from "react-icons/fa";
import { Link } from "react-router-dom";
import LoginProtectedPage from "../components/LoginProtectedPage";
import SocialLogin from "../components/SocialLogin";

export default function SignUp() {
  return (
    <LoginProtectedPage>
      <VStack
        minH="calc(100vh - 128px)"
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Container maxW="md">
          <VStack mb={5}>
            <Text fontWeight={"700"} fontSize={30}>
              회원가입 선택
            </Text>
          </VStack>
          <Card>
            <CardBody>
              <Link
                to="/sign-up/normal"
                style={{ width: "100%", display: "flex" }}
              >
                <Button colorScheme="blue" w="100%" leftIcon={<FaUserPlus />}>
                  일반가입 →
                </Button>
              </Link>
              <SocialLogin />
            </CardBody>
          </Card>
          <VStack mt="3">
            <Text>계정이 이미 있으신가요?</Text>
            <Link to="/login" style={{ width: "100%", display: "flex" }}>
              <Button
                colorScheme="blue"
                variant={"link"}
                w="100%"
                leftIcon={<FaUser />}
              >
                로그인 하러가기 →
              </Button>
            </Link>
          </VStack>
        </Container>
      </VStack>
    </LoginProtectedPage>
  );
}
