import {
  Button,
  Card,
  CardBody,
  Heading,
  Text,
  VStack,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import SendEmailProtected from "../components/SendEmailProtected";
import { useMutation } from "@tanstack/react-query";
import { sendVerificationEmail } from "./api";
import { useState } from "react";
import { FaEnvelope } from "react-icons/fa";
import useUser from "../lib/useUser";

export default function EmailVerifiedError() {
  const { user } = useUser();
  const mutation = useMutation({
    mutationFn: sendVerificationEmail,
  });
  const handleClick = () => {
    mutation.mutate();
  };
  return (
    <SendEmailProtected>
      <VStack
        justifyContent={"center"}
        minHeight="calc(100vh - 128px)"
        w="100%"
      >
        <Heading>이메일 인증을 완료해주세요!</Heading>
        {mutation.isError ? (
          <Text color={"red.400"} fontWeight={"700"}>
            문제가 발생했습니다. 다시 시도해주세요.
          </Text>
        ) : null}
        <Card my={2}>
          {mutation.isSuccess ? (
            <CardBody>
              <VStack>
                <Text fontWeight={"700"}>
                  이메일로 전송된 링크를 확인하세요.
                </Text>
                <Text>{user?.email}</Text>
                <a href={`https://${user?.email.split("@")[1]}`}>
                  <Button variant={"outline"} colorScheme="teal">
                    {user?.email.split("@")[1]} 으로 이동하기 →
                  </Button>
                </a>
              </VStack>
            </CardBody>
          ) : null}
        </Card>
        <Button
          leftIcon={<FaEnvelope />}
          isLoading={mutation.isPending}
          isDisabled={mutation.isSuccess}
          onClick={handleClick}
          colorScheme={"red"}
        >
          {mutation.isSuccess
            ? "전송 완료!"
            : mutation.isError
            ? "인증 이메일 재전송 →"
            : "인증 이메일 보내기 →"}
        </Button>
      </VStack>
    </SendEmailProtected>
  );
}
