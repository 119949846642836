import {
  Button,
  Card,
  CardBody,
  Checkbox,
  Container,
  Divider,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Grid,
  Heading,
  Input,
  Select,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  ISocialSignUpError,
  ISocialSignUpSuccess,
  ISocialSignUpVariables,
  socialSignUp,
} from "../routes/api";

import { Link, useNavigate } from "react-router-dom";
import useUser from "../lib/useUser";
import ProtectedPage from "../components/ProtectedPage";

interface IForm {
  name: string;
  year: string;
  month: string;
  day: string;
  gender: string;
  isPrivacyAndTermsAgreed: boolean;
  isMarketingAgreed: boolean;
}

export default function SocialSignUp() {
  const { isLoggedIn, userLoading, user } = useUser();
  const [mutaionErrorMessage, setMutationErrorMessage] = useState("");
  const [birthdateErrorMessage, setBirthdateErrorMessage] = useState("");
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<IForm>();
  const toast = useToast();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const mutation = useMutation<
    ISocialSignUpSuccess,
    ISocialSignUpError,
    ISocialSignUpVariables
  >({
    mutationFn: socialSignUp,
    onSuccess: () => {
      toast({
        title: `계정 생성이 완료되었습니다!`,
        status: "success",
        position: "top",
        isClosable: true,
        duration: 3000,
      });
      queryClient
        .refetchQueries({ queryKey: ["me"] })
        .then(() => navigate("/"));
    },
    onError: (error) => {
      console.log(error);
      setMutationErrorMessage(error.response.data);
    },
  });

  useEffect(() => {
    if (user?.is_privacy_and_terms_agreed === true) {
      navigate("/");
    }
  }, [user]);

  const validateDate = (
    year: string,
    month: string,
    day: string,
    formattedDate: string
  ) => {
    const date = new Date(formattedDate);
    const now = new Date();

    if (
      isNaN(date.getTime()) ||
      date.getFullYear() < 1900 ||
      date.getFullYear() > now.getFullYear() ||
      date.getFullYear() !== parseInt(year) ||
      date.getMonth() + 1 !== parseInt(month) ||
      date.getDate() !== parseInt(day)
    ) {
      setBirthdateErrorMessage("유효한 생년월일을 입력해주세요.");
      return false;
    } else {
      setBirthdateErrorMessage("");
      return true;
    }
  };

  const onSubmit = ({
    name,
    year,
    month,
    day,
    gender,
    isPrivacyAndTermsAgreed,
    isMarketingAgreed,
  }: IForm) => {
    const formattedDate = `${year}-${month.padStart(2, "0")}-${day.padStart(
      2,
      "0"
    )}`;

    if (!validateDate(year, month, day, formattedDate)) {
      return;
    }

    mutation.mutate({
      name,
      birth_date: formattedDate,
      gender,
      is_privacy_and_terms_agreed: isPrivacyAndTermsAgreed,
      is_marketing_agreed: isMarketingAgreed,
    });
  };

  const validateNameSpace = (name: string) => {
    if (name.trim() === "") {
      return "닉네임을 입력해주세요.";
    }
    if (name.trim() !== name) {
      return "앞 뒤 공백은 허용되지 않습니다.";
    }
  };

  const validateDateSpace = (value: string) => {
    const spaceRemovedValue = value.replace(/\s+/g, "");
    if (spaceRemovedValue === "") {
      return "띄어쓰기는 허용되지 않습니다.";
    }
    if (spaceRemovedValue !== value) {
      return "띄어쓰기는 허용되지 않습니다.";
    }
  };

  return (
    <ProtectedPage>
      <Container>
        <VStack mb={5}>
          <Heading>소셜 회원가입</Heading>
        </VStack>
        {userLoading || !isLoggedIn || !user ? null : (
          <Card>
            <CardBody as="form" onSubmit={handleSubmit(onSubmit)}>
              <VStack>
                <FormControl>
                  <FormLabel>이메일</FormLabel>
                  <Text>{user.email}</Text>
                </FormControl>
                <Divider />
                <FormControl isInvalid={Boolean(errors.name?.message)}>
                  <FormLabel>닉네임</FormLabel>
                  <Input
                    {...register("name", {
                      required: "닉네임을 입력해주세요",
                      maxLength: 20,
                      validate: validateNameSpace,
                      onBlur: (e: React.FocusEvent<HTMLInputElement>) =>
                        setValue("name", e.target.value.trim()),
                    })}
                    maxLength={20}
                    autoComplete="off"
                  />
                  <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
                  {errors.name?.type === "maxLength" ? (
                    <Text color="red.400">닉네임 20자 이하여야 합니다.</Text>
                  ) : null}
                </FormControl>
                <FormControl
                  isInvalid={
                    Boolean(errors.year?.message) ||
                    Boolean(errors.month?.message) ||
                    Boolean(errors.day?.message)
                  }
                >
                  <FormLabel>생년월일</FormLabel>
                  <Grid gridTemplateColumns={"1fr 1fr 1fr"} gap="1" w="100%">
                    <Input
                      id="year"
                      isInvalid={Boolean(errors.year)}
                      {...register("year", {
                        required: "전체 생년월일을 입력해주세요.",
                        validate: validateDateSpace,
                        onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                          setValue("year", e.target.value.replace(/\s+/g, "")),
                      })}
                      placeholder="연"
                      maxLength={4}
                    />
                    <Input
                      id="month"
                      isInvalid={Boolean(errors.month)}
                      {...register("month", {
                        required: "전체 생년월일을 입력해주세요.",
                        validate: validateDateSpace,
                        onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                          setValue("month", e.target.value.replace(/\s+/g, "")),
                      })}
                      placeholder="월"
                      maxLength={2}
                    />
                    <Input
                      id="day"
                      isInvalid={Boolean(errors.day)}
                      {...register("day", {
                        required: "전체 생년월일을 입력해주세요.",
                        validate: validateDateSpace,
                        onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                          setValue("day", e.target.value.replace(/\s+/g, "")),
                      })}
                      placeholder="일"
                      maxLength={2}
                    />
                  </Grid>
                  {errors.year?.message ||
                  errors.month?.message ||
                  errors.day?.message ? (
                    <FormErrorMessage>
                      전체 생년월일을 입력해주세요.
                    </FormErrorMessage>
                  ) : null}
                  {birthdateErrorMessage !== "" && (
                    <FormHelperText color="red.300">
                      {birthdateErrorMessage}
                    </FormHelperText>
                  )}
                </FormControl>
                <FormControl isInvalid={Boolean(errors.gender?.message)}>
                  <FormLabel>성별</FormLabel>
                  <Select
                    {...register("gender", {
                      required: "성별을 선택해주세요.",
                    })}
                    placeholder="선택"
                  >
                    <option value={"male"}>남성</option>
                    <option value={"female"}>여성</option>
                    <option value={"unspecified"}>공개 안함</option>
                  </Select>
                  <FormErrorMessage>{errors.gender?.message}</FormErrorMessage>
                </FormControl>
                <FormControl
                  mt={3}
                  isInvalid={Boolean(errors.isPrivacyAndTermsAgreed?.message)}
                >
                  <Checkbox
                    {...register("isPrivacyAndTermsAgreed", {
                      required: "위 항목은 필수입니다.",
                    })}
                    defaultChecked
                  >
                    <Button variant={"link"} colorScheme="blue">
                      <Link to="/term">이용약관</Link>
                    </Button>{" "}
                    및{" "}
                    <Button variant={"link"} colorScheme="blue">
                      <Link to="/term/privacy">개인정보 처리방침</Link>
                    </Button>{" "}
                    동의 (필수)
                  </Checkbox>
                  {errors.isPrivacyAndTermsAgreed ? (
                    <FormErrorMessage color={"red.400"}>
                      {errors.isPrivacyAndTermsAgreed?.message}
                    </FormErrorMessage>
                  ) : null}
                </FormControl>
                <FormControl>
                  <Checkbox {...register("isMarketingAgreed")} defaultChecked>
                    마케팅 정보 수신 동의 (선택)
                  </Checkbox>
                </FormControl>
                {mutation.isError && (
                  <Text color={"red.400"}>{mutaionErrorMessage}</Text>
                )}
              </VStack>
              <Button
                isLoading={mutation.isPending}
                mt={4}
                colorScheme="red"
                w={"100%"}
                type="submit"
              >
                회원가입
              </Button>
            </CardBody>
          </Card>
        )}
      </Container>
    </ProtectedPage>
  );
}
