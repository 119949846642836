import {
  Button,
  Card,
  CardBody,
  Checkbox,
  Container,
  Divider,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Grid,
  Heading,
  HStack,
  Input,
  Select,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { ChangeEvent, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import {
  ISignUpError,
  ISignUpSuccess,
  ISignUpVariables,
  signUp,
} from "../routes/api";
import LoginProtectedPage from "../components/LoginProtectedPage";
import { Link } from "react-router-dom";

interface IForm {
  email: string;
  username: string;
  name: string;
  password: string;
  password2: string;
  year: string;
  month: string;
  day: string;
  gender: string;
  isPrivacyAndTermsAgreed: boolean;
  isMarketingAgreed: boolean;
}

export default function NormalSignUp() {
  const [mutaionErrorMessage, setMutationErrorMessage] = useState("");
  const [passwordErrorMessage, setPasswordErrorMessage] = useState("");
  const [birthdateErrorMessage, setBirthdateErrorMessage] = useState("");
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<IForm>();

  const mutation = useMutation<ISignUpSuccess, ISignUpError, ISignUpVariables>({
    mutationFn: signUp,
    onSuccess: () => {},
    onError: (error) => {
      console.log(error);
      setMutationErrorMessage(error.response.data);
    },
  });

  const validatePasswords = (password: string, password2: string) => {
    if (password !== password2) {
      setPasswordErrorMessage("패스워드가 일치하지 않습니다.");
      return false;
    } else {
      setPasswordErrorMessage("");
      return true;
    }
  };

  const validateDate = (
    year: string,
    month: string,
    day: string,
    formattedDate: string
  ) => {
    const date = new Date(formattedDate);
    const now = new Date();

    if (
      isNaN(date.getTime()) ||
      date.getFullYear() < 1900 ||
      date.getFullYear() > now.getFullYear() ||
      date.getFullYear() !== parseInt(year) ||
      date.getMonth() + 1 !== parseInt(month) ||
      date.getDate() !== parseInt(day)
    ) {
      setBirthdateErrorMessage("유효한 생년월일을 입력해주세요.");
      return false;
    } else {
      setBirthdateErrorMessage("");
      return true;
    }
  };

  const onSubmit = ({
    email,
    username,
    name,
    password,
    password2,
    year,
    month,
    day,
    gender,
    isPrivacyAndTermsAgreed,
    isMarketingAgreed,
  }: IForm) => {
    const formattedDate = `${year}-${month.padStart(2, "0")}-${day.padStart(
      2,
      "0"
    )}`;

    if (!validatePasswords(password, password2)) {
      return;
    }
    if (!validateDate(year, month, day, formattedDate)) {
      return;
    }
    mutation.mutate({
      email,
      username,
      name,
      password,
      password2,
      birth_date: formattedDate,
      gender,
      is_privacy_and_terms_agreed: isPrivacyAndTermsAgreed,
      is_marketing_agreed: isMarketingAgreed,
    });
  };

  const passwordPattern =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/;

  const validateNameSpace = (name: string) => {
    if (name.trim() === "") {
      return "닉네임을 입력해주세요.";
    }
    if (name.trim() !== name) {
      return "앞 뒤 공백은 허용되지 않습니다.";
    }
  };

  const validateUsername = (username: string) => {
    const spaceRemovedUsername = username.replace(/\s+/g, "");
    if (spaceRemovedUsername === "") {
      return "아이디를 입력해주세요.";
    }
    if (spaceRemovedUsername !== username) {
      return "띄어쓰기는 허용되지 않습니다.";
    }
    if (!/^[A-Za-z0-9]+$/.test(spaceRemovedUsername)) {
      return "아이디는 알파벳과 숫자만 허용됩니다.";
    }
  };

  const validateDateSpace = (value: string) => {
    const spaceRemovedValue = value.replace(/\s+/g, "");
    if (spaceRemovedValue === "") {
      return "띄어쓰기는 허용되지 않습니다.";
    }
    if (spaceRemovedValue !== value) {
      return "띄어쓰기는 허용되지 않습니다.";
    }
  };

  return (
    <LoginProtectedPage>
      <Container>
        <VStack mb={5}>
          <Heading>일반 가입</Heading>
        </VStack>
        <Card>
          <CardBody as="form" onSubmit={handleSubmit(onSubmit)}>
            <VStack>
              <FormControl isInvalid={Boolean(errors.email?.message)}>
                <FormLabel>이메일</FormLabel>
                <Input
                  {...register("email", {
                    required: "이메일을 입력해주세요",
                    pattern: {
                      value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                      message: "유효한 이메일 주소를 입력해주세요.",
                    },
                  })}
                />
                <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={Boolean(errors.username?.message)}>
                <FormLabel>아이디</FormLabel>
                <Input
                  {...register("username", {
                    required: "아이디를 입력해주세요",
                    maxLength: 20,
                    validate: validateUsername,
                    onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                      setValue("username", e.target.value.replace(/\s+/g, "")),
                  })}
                  maxLength={20}
                />
                <FormErrorMessage>{errors.username?.message}</FormErrorMessage>
                {errors.username?.type === "maxLength" ? (
                  <Text color="red.400">아이디는 20자 이하여야 합니다.</Text>
                ) : null}
              </FormControl>
              <FormControl isInvalid={Boolean(errors.name?.message)}>
                <FormLabel>닉네임</FormLabel>
                <Input
                  {...register("name", {
                    required: "닉네임을 입력해주세요",
                    maxLength: 20,
                    validate: validateNameSpace,
                    onBlur: (e: React.FocusEvent<HTMLInputElement>) =>
                      setValue("name", e.target.value.trim()),
                  })}
                  maxLength={20}
                />
                <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
                {errors.name?.type === "maxLength" ? (
                  <Text color="red.400">닉네임 20자 이하여야 합니다.</Text>
                ) : null}
              </FormControl>
              <FormControl isInvalid={Boolean(errors.password?.message)}>
                <FormLabel>패스워드</FormLabel>
                <Input
                  {...register("password", {
                    required: "패스워드를 입력해주세요",
                    pattern: {
                      value: passwordPattern,
                      message:
                        "대문자, 소문자, 숫자, 기호를 포함하여 8자 이상이어야 합니다.",
                    },
                  })}
                  type="password"
                />
                {errors.password?.message ? (
                  <FormErrorMessage>
                    {errors.password?.message}
                  </FormErrorMessage>
                ) : (
                  <FormHelperText>
                    대문자, 소문자, 숫자, 기호를 포함하여 8자 이상이어야 합니다.
                  </FormHelperText>
                )}
              </FormControl>
              <FormControl isInvalid={Boolean(errors.password2?.message)}>
                <FormLabel>패스워드 확인</FormLabel>
                <Input
                  {...register("password2", {
                    required: "패스워드를 입력해주세요",
                  })}
                  type="password"
                />
                <FormErrorMessage>{errors.password2?.message}</FormErrorMessage>
                {passwordErrorMessage !== "" && (
                  <FormHelperText color="red.300">
                    {passwordErrorMessage}
                  </FormHelperText>
                )}
              </FormControl>
              <FormControl
                isInvalid={
                  Boolean(errors.year?.message) ||
                  Boolean(errors.month?.message) ||
                  Boolean(errors.day?.message)
                }
              >
                <FormLabel>생년월일</FormLabel>
                <Grid gridTemplateColumns={"1fr 1fr 1fr"} gap="1" w="100%">
                  <Input
                    id="year"
                    isInvalid={Boolean(errors.year)}
                    {...register("year", {
                      required: "전체 생년월일을 입력해주세요.",
                      validate: validateDateSpace,
                      onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                        setValue("year", e.target.value.replace(/\s+/g, "")),
                    })}
                    placeholder="연"
                    maxLength={4}
                  />
                  <Input
                    id="month"
                    isInvalid={Boolean(errors.month)}
                    {...register("month", {
                      required: "전체 생년월일을 입력해주세요.",
                      validate: validateDateSpace,
                      onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                        setValue("month", e.target.value.replace(/\s+/g, "")),
                    })}
                    placeholder="월"
                    maxLength={2}
                  />
                  <Input
                    id="day"
                    isInvalid={Boolean(errors.day)}
                    {...register("day", {
                      required: "전체 생년월일을 입력해주세요.",
                      validate: validateDateSpace,
                      onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                        setValue("day", e.target.value.replace(/\s+/g, "")),
                    })}
                    placeholder="일"
                    maxLength={2}
                  />
                </Grid>
                {errors.year?.message ||
                errors.month?.message ||
                errors.day?.message ? (
                  <FormErrorMessage>
                    전체 생년월일을 입력해주세요.
                  </FormErrorMessage>
                ) : null}
                {birthdateErrorMessage !== "" && (
                  <FormHelperText color="red.300">
                    {birthdateErrorMessage}
                  </FormHelperText>
                )}
              </FormControl>
              <FormControl isInvalid={Boolean(errors.gender?.message)}>
                <FormLabel>성별</FormLabel>
                <Select
                  {...register("gender", { required: "성별을 선택해주세요." })}
                  placeholder="선택"
                >
                  <option value={"male"}>남성</option>
                  <option value={"female"}>여성</option>
                  <option value={"unspecified"}>공개 안함</option>
                </Select>
                <FormErrorMessage>{errors.gender?.message}</FormErrorMessage>
              </FormControl>
              <FormControl
                mt={3}
                isInvalid={Boolean(errors.isPrivacyAndTermsAgreed?.message)}
              >
                <Checkbox
                  {...register("isPrivacyAndTermsAgreed", {
                    required: "위 항목은 필수입니다.",
                  })}
                  defaultChecked
                >
                  <Button variant={"link"} colorScheme="blue">
                    <Link to="/term">이용약관</Link>
                  </Button>{" "}
                  및{" "}
                  <Button variant={"link"} colorScheme="blue">
                    <Link to="/term/privacy">개인정보 처리방침</Link>
                  </Button>{" "}
                  동의 (필수)
                </Checkbox>
                {errors.isPrivacyAndTermsAgreed ? (
                  <FormErrorMessage color={"red.400"}>
                    {errors.isPrivacyAndTermsAgreed?.message}
                  </FormErrorMessage>
                ) : null}
              </FormControl>
              <FormControl>
                <Checkbox {...register("isMarketingAgreed")} defaultChecked>
                  마케팅 정보 수신 동의 (선택)
                </Checkbox>
              </FormControl>
              {mutation.isError && (
                <Text color={"red.400"}>{mutaionErrorMessage}</Text>
              )}
            </VStack>
            <Button
              isLoading={mutation.isPending}
              mt={4}
              colorScheme="red"
              w={"100%"}
              type="submit"
            >
              회원가입
            </Button>
            {mutation.isSuccess && (
              <VStack p={5} mt="5" bg="green.100" borderRadius={5}>
                <Text color="black">계정 생성이 완료되었습니다!</Text>
                <Link to="/login">
                  <Button w="200px" colorScheme="green">
                    로그인 하러가기 →
                  </Button>
                </Link>
              </VStack>
            )}
          </CardBody>
        </Card>
      </Container>
    </LoginProtectedPage>
  );
}
