import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Heading,
  Input,
  Text,
  VStack,
} from "@chakra-ui/react";
import { IUser } from "../lib/useUser";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { useMutation } from "@tanstack/react-query";
import {
  changePassword,
  IChangePasswordError,
  IChangePasswordSuccess,
  IChangePasswordVariables,
} from "../routes/api";

interface IForm {
  oldPassword: string;
  newPassword: string;
  newPassword2: string;
}

export default function ChangePasswordCard() {
  const [mutaionErrorMessage, setMutationErrorMessage] = useState("");
  const [passwordErrorMessage, setPasswordErrorMessage] = useState("");
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<IForm>();
  const mutation = useMutation<
    IChangePasswordSuccess,
    IChangePasswordError,
    IChangePasswordVariables
  >({
    mutationFn: changePassword,
    onSuccess: () => reset(),
    onError: (error) => {
      setMutationErrorMessage(error.response.data);
    },
  });

  const validatePasswords = (password: string, password2: string) => {
    if (password !== password2) {
      setPasswordErrorMessage("패스워드가 일치하지 않습니다.");
      return false;
    } else {
      setPasswordErrorMessage("");
      return true;
    }
  };

  const onSubmit = ({ oldPassword, newPassword, newPassword2 }: IForm) => {
    if (!validatePasswords(newPassword, newPassword2)) {
      return;
    }
    mutation.mutate({
      old_password: oldPassword,
      new_password: newPassword,
      new_password2: newPassword2,
    });
  };

  const passwordPattern =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/;
  return (
    <>
      <Flex justifyContent={"center"} w="100%">
        <Text fontSize={24}>패스워드 변경</Text>
      </Flex>
      <Card as="form" onSubmit={handleSubmit(onSubmit)} mt={3}>
        <CardBody>
          <VStack w="100%">
            <FormControl>
              <FormLabel>기존 패스워드</FormLabel>
              <Input
                {...register("oldPassword", {
                  required: "기존 패스워드를 입력해주세요.",
                })}
                type="password"
              />
            </FormControl>
            <FormControl isInvalid={Boolean(errors.newPassword?.message)}>
              <FormLabel>새 패스워드</FormLabel>
              <Input
                {...register("newPassword", {
                  required: "새 패스워드를 입력해주세요",
                  pattern: {
                    value: passwordPattern,
                    message:
                      "대문자, 소문자, 숫자, 기호를 포함하여 8자 이상이어야 합니다.",
                  },
                })}
                type="password"
              />
              {errors.newPassword?.message ? (
                <FormErrorMessage>
                  {errors.newPassword?.message}
                </FormErrorMessage>
              ) : (
                <FormHelperText>
                  대문자, 소문자, 숫자, 기호를 포함하여 8자 이상이어야 합니다.
                </FormHelperText>
              )}
            </FormControl>
            <FormControl isInvalid={Boolean(errors.newPassword2?.message)}>
              <FormLabel>패스워드 확인</FormLabel>
              <Input
                {...register("newPassword2", {
                  required: "패스워드를 입력해주세요",
                })}
                type="password"
              />
              <FormErrorMessage>
                {errors.newPassword2?.message}
              </FormErrorMessage>
              {passwordErrorMessage !== "" && (
                <FormHelperText color="red.300">
                  {passwordErrorMessage}
                </FormHelperText>
              )}
            </FormControl>
            {mutation.isError && (
              <Text color={"red.400"}>{mutaionErrorMessage}</Text>
            )}
          </VStack>
        </CardBody>
        <CardFooter>
          <VStack w="100%">
            <Button
              isLoading={mutation.isPending}
              type="submit"
              colorScheme="teal"
              w="100%"
            >
              패스워드 변경
            </Button>
            {mutation.isSuccess && (
              <Card w="100%" color="green" bg="white">
                <CardBody>
                  <VStack>
                    <Text>"수정이 완료되었습니다."</Text>
                  </VStack>
                </CardBody>
              </Card>
            )}
          </VStack>
        </CardFooter>
      </Card>
    </>
  );
}
