import { ThemeConfig, extendTheme } from "@chakra-ui/react";

const config: ThemeConfig = {
  initialColorMode: "light",
  useSystemColorMode: false,
};

const theme = extendTheme({
  config,
  /* styles: {
    global: (props: any) => ({
      body: {
        bg: props.colorMode === "dark" ? "black" : "white",
      },
    }),
  }, */
});

export default theme;
