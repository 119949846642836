import { Box } from "@chakra-ui/react";
import { Outlet, useLocation } from "react-router-dom";
import Header from "./Header";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import Footer from "./Footer";
import { useEffect } from "react";
import ReactGA from "react-ga4";

export default function Root() {
  const location = useLocation();

  useEffect(() => {
    // 페이지 이동 시 페이지뷰 전송
    ReactGA.send({ hitType: "pageview", page: location.pathname });
  }, [location]);
  return (
    <Box>
      <Header />
      <Box mb="20" minHeight="calc(100vh - 64px)">
        <Outlet />
      </Box>
      <ReactQueryDevtools />
      <Footer />
    </Box>
  );
}
