import { createBrowserRouter, Navigate } from "react-router-dom";
import Root from "./components/Root";
import Home from "./routes/Home";
import Todolist from "./routes/Todolist";
import NotFound from "./routes/NotFound";
import EmailVerifiedSuccess from "./routes/EmailVerifiedSuccess";
import EmailVerifiedFailed from "./routes/EmailVerifiedFailed";
import SendVerificationEmail from "./routes/SendVerificationEmail";
import VerifyEmail from "./routes/VerifyEmail";
import Me from "./routes/Me";
import SignUp from "./routes/SignUp";
import NormalSignUp from "./routes/NormalSignUp";
import Login from "./routes/Login";
import KakaoConfirm from "./routes/KakaoConfirm";
import SocialSignUp from "./routes/SocialSignUp";
import Term from "./routes/Term";
import Privacy from "./routes/Privacy";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <NotFound />,
    children: [
      {
        path: "",
        element: <Navigate to="/todolist" replace />,
      },
      {
        path: "todolist",
        element: <Todolist />,
      },
      {
        path: "email-verified-success",
        element: <EmailVerifiedSuccess />,
      },
      {
        path: "email-verified-failed",
        element: <EmailVerifiedFailed />,
      },
      {
        path: "send-verification-email",
        element: <SendVerificationEmail />,
      },
      {
        path: "verify-email/:token",
        element: <VerifyEmail />,
      },
      {
        path: "me",
        element: <Me />,
      },
      {
        path: "sign-up",
        children: [
          { path: "", element: <SignUp /> },
          { path: "normal", element: <NormalSignUp /> },
          { path: "social", element: <SocialSignUp /> },
        ],
      },
      {
        path: "login",
        element: <Login />,
      },
      {
        path: "social",
        children: [
          {
            path: "kakao",
            element: <KakaoConfirm />,
          },
        ],
      },
      {
        path: "term",
        children: [
          {
            path: "",
            element: <Term />,
          },
          {
            path: "privacy",
            element: <Privacy />,
          },
        ],
      },
    ],
  },
]);

export default router;
