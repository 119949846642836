import { useQuery } from "@tanstack/react-query";
import { getMe } from "../routes/api";

export interface IUser {
  email: string;
  username: string;
  name: string;
  birth_date: string;
  gender: string;
  is_email_verified: boolean;
  is_privacy_and_terms_agreed: boolean;
  is_marketing_agreed: boolean;
  has_usable_password: boolean;
}

export default function useUser() {
  const { isLoading, data, isError } = useQuery<IUser>({
    queryKey: ["me"],
    queryFn: getMe,
    retry: false,
  });

  return {
    userLoading: isLoading,
    user: data,
    isLoggedIn: !isError,
  };
}
