import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useUser from "../lib/useUser";

interface ISendEmailProtectedProps {
  children: React.ReactNode;
}

export default function SendEmailProtected({
  children,
}: ISendEmailProtectedProps) {
  const { isLoggedIn, userLoading, user } = useUser();
  const navigate = useNavigate();
  useEffect(() => {
    if (!userLoading && isLoggedIn) {
      if (user) {
        if (user.is_email_verified === true) {
          navigate("/");
        }
      }
    } else if (!userLoading && !isLoggedIn) {
      navigate("/");
    }
  }, [userLoading, isLoggedIn, user, navigate]);
  return <>{children}</>;
}
