import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  GridItem,
  Heading,
  HStack,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import {
  FaAngleRight,
  FaBold,
  FaInstagram,
  FaLightbulb,
  FaYoutube,
} from "react-icons/fa";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <>
      <Divider />
      <Box py="3">
        <Container>
          <Button
            as="a"
            href="https://blog.naver.com/beself_official/223557384855"
            target="_blank"
            w="100%"
            py="25"
          >
            <Grid w="100%" gridTemplateColumns={"1fr 3fr 1fr"}>
              <GridItem
                display={"flex"}
                justifyContent={"flex-start"}
                alignItems={"center"}
              >
                <FaLightbulb />
              </GridItem>
              <GridItem display={"flex"} justifyContent={"center"}>
                비셀프 투두리스트 100% 활용법
              </GridItem>
              <GridItem
                fontSize={20}
                display={"flex"}
                justifyContent={"flex-end"}
                alignItems={"center"}
              >
                <FaAngleRight />
              </GridItem>
            </Grid>
          </Button>
          <Button
            as="a"
            href="https://blog.naver.com/beself_official/223557503848"
            target="_blank"
            w="100%"
            py="25"
            mt="3"
          >
            <Grid w="100%" gridTemplateColumns={"1fr 3fr 1fr"}>
              <GridItem
                display={"flex"}
                justifyContent={"flex-start"}
                alignItems={"center"}
              >
                <FaLightbulb />
              </GridItem>
              <GridItem display={"flex"} justifyContent={"center"}>
                비셀프 투두리스트를 '앱'처럼 쓰는 방법
              </GridItem>
              <GridItem
                fontSize={20}
                display={"flex"}
                justifyContent={"flex-end"}
                alignItems={"center"}
              >
                <FaAngleRight />
              </GridItem>
            </Grid>
          </Button>
          <Button
            as="a"
            href="https://forms.gle/TqTS144gP2JaNUEP8"
            target="_blank"
            w="100%"
            py="25"
            mt="3"
          >
            <Grid w="100%" gridTemplateColumns={"1fr 3fr 1fr"}>
              <GridItem
                display={"flex"}
                justifyContent={"flex-start"}
                alignItems={"center"}
              >
                <FaLightbulb />
              </GridItem>
              <GridItem display={"flex"} justifyContent={"center"}>
                [10일 동안 나를 알아가는 질문] 무료 PDF
              </GridItem>
              <GridItem
                fontSize={20}
                display={"flex"}
                justifyContent={"flex-end"}
                alignItems={"center"}
              >
                <FaAngleRight />
              </GridItem>
            </Grid>
          </Button>
          <Button
            as="a"
            href="https://blog.naver.com/beself_official/223296669457"
            target="_blank"
            w="100%"
            py="30"
            mt="3"
          >
            <Grid w="100%" gridTemplateColumns={"1fr 3fr 1fr"}>
              <GridItem
                display={"flex"}
                justifyContent={"flex-start"}
                alignItems={"center"}
              >
                <FaLightbulb />
              </GridItem>
              <GridItem display={"flex"} justifyContent={"center"}>
                <VStack>
                  <Text>[나 자신이 되는 법] 전자책 +</Text>
                  <Text>[나를 제대로 아는 방법] 워크북</Text>
                </VStack>
              </GridItem>
              <GridItem
                fontSize={20}
                display={"flex"}
                justifyContent={"flex-end"}
                alignItems={"center"}
              >
                <FaAngleRight />
              </GridItem>
            </Grid>
          </Button>
        </Container>
      </Box>
      <Box bgColor={"black"} py="20px">
        <Container alignItems={"flex-start"}>
          <Heading mb="3" textAlign={"center"} color={"gray.400"}>
            비셀프
          </Heading>
          <VStack color={"gray.400"}>
            <VStack fontSize={"xs"}>
              <Text fontWeight={"600"}>FOLLOW US</Text>
              <VStack>
                <a
                  href="https://www.instagram.com/be_self.2023/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <HStack justifyContent={"center"} alignItems={"center"}>
                    <FaInstagram />
                    <Text>INSTAGRAM</Text>
                  </HStack>
                </a>
                <a
                  href="https://www.youtube.com/channel/UCwTjBcLiXx9vYIije6l_kNw"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <HStack justifyContent={"center"} alignItems={"center"}>
                    <FaYoutube />
                    <Text>YOUTUBE</Text>
                  </HStack>
                </a>
                <a
                  href="https://blog.naver.com/beself_official"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <HStack justifyContent={"center"} alignItems={"center"}>
                    <FaBold />
                    <Text>BLOG</Text>
                  </HStack>
                </a>
              </VStack>
            </VStack>
          </VStack>
          <VStack my="5" color="gray.400">
            <Link to="/term">
              <Text fontSize={"sm"}>이용약관</Text>
            </Link>
            <Link to="/term/privacy">
              <Text fontSize={"sm"}>개인정보 처리방침</Text>
            </Link>
          </VStack>
          <VStack fontSize={"xs"} color={"gray.600"}>
            <Text fontWeight={"800"} fontSize={"sm"} mb="1">
              비셀프
            </Text>
            <Text>사업자등록번호: 321-23-01749</Text>
            <Text>통신판매업신고번호: 2024-용인기흥-0739</Text>
            <Text>대표: 김형준</Text>
            <Text>주소: 경기도 용인시 기흥구 강남서로9,</Text>
            <Text>7층 703-A860호(구갈동)</Text>
            <Text>전화: 070-8098-3940</Text>
            <Text>이메일: beself365@gmail.com</Text>
            <Text mt="3">Copyright © BeSelf. All rights reserved.</Text>
          </VStack>
        </Container>
      </Box>
    </>
  );
}
