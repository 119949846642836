import {
  Container,
  Heading,
  ListItem,
  Stack,
  styled,
  Text,
  UnorderedList,
} from "@chakra-ui/react";

const Article = styled(Text, {
  baseStyle: {
    fontSize: "16",
    fontWeight: "700",
    my: "3",
  },
});

const CustomList = styled(ListItem, {
  baseStyle: {
    fontSize: "13",
    ml: "4",
  },
});

export default function Term() {
  return (
    <Container maxW="3xl">
      <Stack>
        <Heading mb="3">서비스 이용약관</Heading>
        <Article>제1조 (목적)</Article>
        <p>
          본 약관은 비셀프(이하 ‘회사’라고 함)가 제공하는 ‘비셀프'의 온라인
          서비스(이하 ‘서비스’라고 함)를 이용함에 있어서 회원과 회사 간의 권리,
          의무 및 책임사항, 기타 필요한 사항을 규정함을 목적으로 합니다.
        </p>
        <Article>제2조 (약관의 공지 및 효력, 약관의 변경)</Article>
        <p>
          1. 이 약관의 내용은 온라인 상의 공지 및 기타 방법에 의한 회원 공지를
          통해 효력이 발생합니다.
          <br /> 2. 회사는 합리적인 사유가 발생한 경우에는 사전 고지 없이 이
          약관을 변경할 수 있습니다. 회사는 변경된 약관을 제1항과 같은 방법으로
          회원에 통지하며, 별도로 정하지 않는 한 회원이 변경된 약관을 통지받은
          때부터 효력이 발생합니다.
          <br /> 3. 회원이 변경된 약관에 동의하지 않을 경우 가입 탈퇴를 요청할
          수 있습니다. 회원이 변경된 약관의 효력 발생일 이후 서비스를 계속
          이용할 경우에는 변경된 약관에 동의한 것으로 간주됩니다.
          <br />
          4. 변경된 약관에 대한 정보를 통지 받았음에도 이에 대한 부지로 발생하는
          회원의 피해에 대해서는 회사가 책임지지 않습니다.
          <br />
          5. 본 약관은 회원이 본 약관에 동의한 날로부터 본 약관 제15조에 따른
          이용계약의 해지 시까지 적용하는 것을 원칙으로 합니다. 단 본 약관의
          일부 조항은 이용계약의 해지 후에도 유효하게 적용될 수 있습니다.
        </p>
        <Article>제3조 (약관 외 준칙)</Article>
        <p>
          1. 이 약관에 명시되지 않은 사항은 전기통신기본법, 전기통신사업법,
          정보통신망이용촉진 및 정보보호 등에 관한 법률 및 기타 관련 법령 또는
          상관례에 따릅니다.
          <br />
          2. 회사는 개별 서비스에 대한 운영정책 및 규칙 등 세부적인 사항(이하
          '세부지침'이라 함)을 정할 수 있으며, 그 내용은 해당 서비스의
          이용안내를 통하여 공지합니다. 만일 본 약관과 세부지침의 내용이 충돌할
          경우 세부지침에 따릅니다.
        </p>
        <Article>제4조 (용어의 정의)</Article>
        <p>
          이 약관에서 사용하는 용어의 정의는 다음과 같습니다.
          <br />
          1. 회원 : 회사의 약관에 동의하여 이용자 아이디(ID) 및 비밀번호를 부여
          받아 서비스 이용계약을 체결한 자
          <br />
          2. 가입 : 회원이 되고자 하는 자가 본 약관에 동의하여 회사의 서비스
          이용 신청 양식에 필요 정보를 기입하고, 서비스 이용계약을 청약, 회사의
          승인을 얻는 것
          <br />
          3. 아이디(ID) : 회사의 회원으로 가입한 자가 서비스를 이용하고자 할
          경우에 대비하여 회원의 동일성 확인을 위해 회원이 문자 또는 숫자의
          조합으로 정한 회원의 고유명칭.
          <br />
          4. 비밀번호 : 아이디를 이용한 서비스 이용신청시 회원의 동일성 확보를
          위하여 회원이 정하고 회사와 공유하는 문자 숫자의 조합정보
          <br />
          5. 운영자 : 회사에서 서비스의 전반적인 관리와 원활한 운영을 위하여
          선정한 사람
          <br />
          6. 탈퇴 : 회원이 기간만료 또는 기타 제반 사유로 서비스 이용계약을
          종료하는 것
        </p>
        <Article>제5조 (이용계약의 성립)</Article>
        <p>
          1. 회원이 본 서비스의 가입을 신청하기 위해서는 먼저 본 약관을 읽고
          동의하여야 합니다. 온라인상의 “동의함” 버튼을 누르면 이 약관에
          동의하는 것으로 간주됩니다.
          <br />
          2. 서비스 이용계약은 서비스 이용 희망자가 본 약관에 동의한 후 서비스
          이용신청서에 신청자의 실질 정보를 입력하여 회사에 신청하고 회사가 이를
          심사, 승낙함으로써 성립하며, 회사는 신청자의 실명 확인 절차를 밟을 수
          있습니다.
          <br />
          3. 단, 만 14세 미만의 이용자는 관련 법률에 따라 회사가 정한 가입 양식
          외에 별도의 법정대리인의 동의절차를 완료한 후에 서비스를 이용할 수
          있습니다. 만 14세 미만 아동이 본 서비스를 이용하고자 할 경우,
          법정대리인의 동의서를 받아서 개인정보처리책임자의
          이메일(beself365@gamil.com)로 송부하여 주시기 바랍니다. 회사는
          법정대리인의 동의를 받지 않은 만 14세 미만 아동의 가입이 확인될 경우
          해당 아동의 계정 등 정보를 삭제할 수 있습니다.
          <br />
          4. 회사는 다음 각 호에 해당하는 이용신청에 대하여는 가입을 거절하거나
          취소할 수 있으며, 또한 실명으로 등록하지 않은 사용자는 일체의 권리를
          주장할 수 없습니다.
          <UnorderedList>
            <CustomList>서비스 이용신청서의 내용이 허위인 때</CustomList>
            <CustomList>
              본 서비스를 이용하면서 사회의 안녕, 질서, 미풍양속을 저해하는
              행위를 한 때
            </CustomList>
            <CustomList>
              타인의 서비스 이용을 방해하거나, 정보를 도용한 때
            </CustomList>
            <CustomList>
              전기통신기본법, 전기통신사업법, 방송통신심의위원회 심의규정,
              정보통신 윤리강령, 프로그램 보호법 및 기타 관련 법령과 약관이
              금지하는 행위를 한 때
            </CustomList>
            <CustomList>
              제 4호에 해당하는 사유로 형사처벌을 받거나 회사의 기타 서비스
              제공회사로부터 서비스 이용이 거절된 전력이 있는 때
            </CustomList>
            <CustomList>
              기타 회사가 정한 이용신청서에 기재사항이 미비한 경우
            </CustomList>
            <CustomList>
              영리 추구 혹은 부정한 용도로 본 서비스를 이용한 때
            </CustomList>
            <CustomList>
              기타 규정한 제반 사항을 위반하며 신청하는 경우
            </CustomList>
          </UnorderedList>
          5. 회사는 다음 각 호의 내용에 해당하는 경우 이용신청에 대한 승낙을
          제한할 수 있고, 그 사유가 해소 될 때까지 승낙을 유보할 수 있습니다.
          <UnorderedList>
            <CustomList>회사의 서비스 관련 설비에 여유가 없는 경우</CustomList>
            <CustomList>회사의 기술상 지장이 있는 경우</CustomList>
            <CustomList>기타 회사의 사정상 필요하다고 인정되는 경우</CustomList>
          </UnorderedList>
        </p>
        <Article>제6조 (개인정보 보호 정책)</Article>
        <p>
          1. 회사는 관계법령이 정하는 바에 따라 회원 개인정보 보호, 관리를 위해
          필요한 조치를 취합니다. 이러한 사항은 회사의 개인정보 보호정책에 따른
          개인정보 처리방침에 자세히 명시되어 있으며, 회사는 이용자의 귀책사유로
          인하여 노출된 정보에 대해서는 책임을 지지 않습니다.
          <br />
          2. 회사는 회원에게 다양한 정보 서비스 및 관련 혜택을 제공할 목적으로
          회원의 정보를 이용할 수 있습니다. 회원이 본 약관에 따라 회사에 대한
          서비스 이용신청서에 회원정보를 기재하고 이용신청을 하는 것은, 회사가
          본 약관에 따라 이용신청서에 기재된 회원정보를 수집, 이용 및 제공하는
          것에 동의하는 것으로 간주됩니다.
          <br />
          3. 회사는 회원 정보를 제3자인 제휴사에게 제공할 경우 제휴 업체, 이용
          목적, 이용할 회원 정보의 내용 등을 사전에 공지하고 회원의 동의를
          얻습니다. 회원에게는 이와 같은 개인정보 제3자 제공에 대한 거절권이
          보장되어 있으며, 개인정보 제3자 제공에 동의한 이후에도 전자우편 등을
          통하여 동의를 철회할 수 있습니다.
          <br />
          4. 회원은 개인정보의 보호 및 관리를 위하여 서비스의 개인정보관리에서
          수시로 본인의 개인정보를 수정‧삭제할 수 있습니다. 회원이 방문 및 서면,
          전화, 전자우편 등을 통하여 또는 회원 ID 등을 이용하여 개인정보의 수집,
          이용, 제3자 제공에 대한 동의의 철회를 요청하는 경우, 회사는 본인
          여부를 확인하고 법령에 다르게 규정하고 있는 경우를 제외하고는 해당
          개인정보를 파기하는 등 신속히 조치를 취합니다. 회사는 회원의 동의철회
          요청에 따라 당해 이용자의 개인정보를 파기하는 등 조치를 취한 경우에는
          그 사실을 회원에게 지체 없이 통지합니다.
          <br />
          5. 회원이 방문 또는 서면, 전화, 전자우편, 전자서명을 통하여 또는
          이용자 ID 등을 이용하여 자신의 개인정보에 대한 열람 또는 정정을
          요구하는 경우, 회사는 본인 여부를 확인하고 지체 없이 필요한 조치를
          취합니다. 회사는 회원의 대리인이 방문하여 열람 또는 정정을 요구하는
          경우에는 회원의 진정한 대리인인지 여부를 확인합니다. 이 경우 회사는
          대리관계를 나타내는 증표를 제시하도록 요구할 수 있습니다.
          <br />
          6. 회사는 개인정보의 수집목적 또는 제공받은 목적을 달성한 때에는 당해
          개인정보를 지체 없이 파기합니다. 다만, 다음 각호의 1에 해당하는
          경우에는 예외로 합니다.
          <UnorderedList>
            <CustomList>
              상법 등 법령의 규정에 의하여 개인정보를 보호할 필요성이 있는 경우
            </CustomList>
            <CustomList>
              보유기간을 미리 이용자에게 고지하거나 명시한 경우 및 개별적으로
              이용자의 동의를 받은 경우
            </CustomList>
            <CustomList>
              회원이 탈퇴한 날로부터 30일이 경과하지 않은 때
            </CustomList>
          </UnorderedList>
        </p>
        <Article>제7조 (회사의 의무)</Article>
        <p>
          1. 회사는 이 약관에서 정한 바에 따라 계속적이고 안정적인 서비스의
          제공을 위하여 지속적으로 노력하며, 설비에 장애가 생긴 때에는 수리
          복구에 최선을 다합니다. 다만, 천재지변, 비상사태를 비롯한 부득이한
          상황이 발생한 경우에는 그 서비스를 일시 중단할 수 있습니다.
          <br />
          2. 회사는 서비스 제공과 관련하여 알게 된 회원의 개인정보를 본인의 승낙
          없이 제3자에게 누설 또는 공개 배포하지 않습니다. 단, 다음에 해당하는
          사항에 대해서는 예외로 규정합니다.
          <UnorderedList>
            <CustomList>
              전기통신기본법 등 기타 법률의 규정에 의거 국가기관이 요구하는 경우
            </CustomList>
            <CustomList>
              범죄에 대한 수사상의 목적이 있거나 방송통신심의위원회의 요청이
              있는 경우 및 기타 관계 법령에서 정한 절차에 따른 요청이 있는 경우
            </CustomList>
          </UnorderedList>
          3. 회사는 회원으로부터 제기되는 의견이나 불만이 정당하다고 인정될
          경우에는 해당사항을 즉시 처리해야 합니다. 다만, 즉시 처리가 곤란한
          경우 회원에게 그 사유와 처리 일정을 통보합니다.
        </p>
        <Article>제8조 (회원의 의무)</Article>
        <p>
          1. 회원은 자신의 ID 및 비밀번호를 관리할 의무가 있으며, 그와 관련하여
          회원의 귀책사유로 말미암아 발생하는 모든 결과에 대한 책임은 회원에게
          있습니다. 또한 제3자가 본인의 ID를 사용하고 있음을 인지한 경우에는
          해당 회원은 바로 회사에 통보하여야 하고 회사의 안내가 있는 경우에는
          그에 따라야 합니다.
          <br />
          2. 회원은 서비스를 이용할 때 다음 각 호의 행위를 하여서는 안됩니다.
          <UnorderedList>
            <CustomList>
              다른 회원의 ID와 비밀번호, 개인정보 등을 도용하는 행위
            </CustomList>
            <CustomList>
              서비스에서 얻은 정보를 회사의 승낙 없이 회원의 서비스 이용 이외
              목적으로 복제하거나 이를 출판 및 방송 등에 사용하거나 제3자에게
              제공하는 행위
            </CustomList>
            <CustomList>
              회사 또는 타인의 저작권 등 권리를 침해하는 행위
            </CustomList>
            <CustomList>
              공공질서 및 미풍양속에 위반되는 내용 또는 정보통신망에 장애를
              유발시킬 수 있는 정보, 문장, 도형 등을 유포하는 행위
            </CustomList>
            <CustomList>범죄와 직, 간접적으로 결부되는 행위</CustomList>
            <CustomList>기타 법령에 위배되는 행위</CustomList>
            <CustomList>
              회원 가입 신청 또는 정보 변경시 허위내용을 기재하는 행위
            </CustomList>
            <CustomList>
              회사의 운영진, 직원 또는 관계자를 사칭하는 행위
            </CustomList>
            <CustomList>
              회사 승인을 받지 않고 다른 사용자의 개인정보를 수집 저장하는 행위
            </CustomList>
          </UnorderedList>
          3. 회원은 회사의 사전 승낙 없이는 서비스를 이용한 영업활동을 할 수
          없으며, 영업활동의 결과와 회원이 약관에 위반한 영업활동을 이용하여
          발생한 결과에 대하여 회사는 책임을 지지 않습니다. 회원은 이와 같은
          영업활동으로 인하여 회사가 손해를 입은 경우에는 이를 배상하여야
          합니다.
          <br />
          4. 회원은 회사의 명시적인 동의가 없는 한 서비스의 이용권한, 기타 이용
          계약상 지위를 타인에게 양도 또는 증여할 수 없으며, 이를 담보로 제공할
          수 없습니다.
          <br />
          5. 회원은 마이페이지를 통해 언제든지 개인정보를 열람하고 수정할 수
          있습니다. 회원은 이용 신청시 기재한 사항이 변경되었을 경우에는 즉시
          온라인 기타 방법에 의하여 수정하여야 하고 미변경으로 인하여 발생되는
          문제에 대하여 회사는 책임을 지지 않습니다. 기타 개인정보와 관련된
          사항은 회사의 개인정보처리방침을 참고해주시기바랍니다.
        </p>
        <Article>제9조 (서비스 이용 방법)</Article>
        <p>
          회원은 회사를 통한 회원가입 시 발급된 ID로 서비스를 이용할 수
          있습니다.
        </p>
        <Article>제10조 (정보의 제공)</Article>
        <p>
          회사는 영업정책 변경 등 회원으로서 서비스 이용 중 알아야 할 필요가
          있는 정보가 발생하는 경우, 서비스상의 공지나 전자우편 등을 이용하여
          관련 내용을 회원에게 제공합니다.
        </p>
        <Article>제11조 (서비스의 사용료)</Article>
        <p>
          1. 서비스는 회원으로 등록한 모든 사람들이 무료로 사용할 수 있습니다.
          <br />
          2. 회사에서 서비스를 유료화할 경우 유료화의 시기, 정책, 비용에 대하여
          유료화 실시 이전에 서비스에 고지합니다.
        </p>
        <Article>제12조 (서비스 이용시간)</Article>
        <p>
          1. 서비스 이용시간은 당사의 업무상 또는 기술상 특별한 지장이 없는 한
          연중무휴, 1일 24시간을 원칙으로 합니다.
          <br />
          2. 회사가 정기점검 등 불가피한 사정으로 서비스제공을 중단하고자 할
          때에는 사전에 서비스 중단사유 및 중단시간을 고지합니다.
        </p>
        <Article>제13조 (서비스 이용 책임)</Article>
        <p>
          회원은 본 서비스를 이용하여 해킹, 돈벌이 광고, 음란사이트를 통한
          상업행위, 상용S/W 불법배포 등의 불법행위를 할 수 없습니다. 회원이 이를
          위반한 경우 발생한 결과 및 손해, 관계기관에 의한 법적 조치 등에
          관해서는 회원이 모든 책임을 부담합니다.
        </p>
        <Article>제14조 (서비스 제공의 중지)</Article>
        <p>
          1. 회사는 다음 각 호에 해당하는 경우 서비스 제공을 중지할 수 있습니다.
          <UnorderedList>
            <CustomList>
              서비스용 설비의 보수 등 공사로 인한 부득이한 경우
            </CustomList>
            <CustomList>
              전기통신사업법에 규정된 기간통신사업자가 전기통신 서비스를
              중지했을 경우
            </CustomList>
            <CustomList>기타 불가항력적 사유가 있는 경우</CustomList>
          </UnorderedList>
          2. 회사는 국가비상사태, 정전, 서비스 설비의 장애 또는 서비스 이용의
          폭주 등과 같은 불가피한 사유로 서비스 제공에 장애가 발생한 경우
          서비스의 전부 또는 일부를 제한하거나 정지할 수 있으며, 이로 인한
          정보의 삭제, 미전송 또는 기타 통신 데이터의 손실 등에 대해 관련 책임을
          부담하지 않습니다.
          <br />
          3. 회사가 제1항 및 제 2항의 규정에 의하여 서비스 이용을 제한하거나
          중지하는 경우, 가능한 한 사전에 회원에게 그 사유 및 제한기간 등을
          고지합니다.
          <br />
          4. 이용자가 본 약관 내용에 위배되는 행동을 한 경우, 회사는 당해 회원에
          한하여 별도 통지없이 임의로 서비스 사용을 제한할 수 있습니다. 이 경우
          회사는 해당 회원의 접속을 금지할 수 있으며, 아이디(ID), 게시한 내용의
          전부 또는 일부를 임의로 삭제할 수 있습니다. 회원이 1년 이상 서비스를
          이용하지 아니하는 경우에도 안내 메일 또는 공지사항으로 서비스 중지가
          가능함을 통지한 후 1주일간의 통지 기간을 거쳐 서비스 사용을 중지할 수
          있습니다.
          <br />
          5. 회사는 회원이 가입 시 설정하는 아이디(ID) 및 기타 정보에 대한
          이용률, 가입자의 본 서비스 이용 목적과 기타 제반 사유 등에 비추어,
          아이디(ID) 및 기타 정보를 변경, 삭제하여야 할 필요성이 있을 때에는
          가입자에게 이를 권고할 수 있습니다. 회원이 이에 이의를 제기하지 아니할
          경우 회사는 임의로 위 사항을 변경 또는 삭제 할 수 있습니다.
        </p>
        <Article>제15조 (계약의 해지 및 휴면전환)</Article>
        <p>
          1. 회원이 이용계약을 해지하고자 하는 때에는 회원 본인이 온라인 또는
          기타 방법을 통하여 회사에 해지 신청을 하여야 합니다.
          <br />
          2. 회사는 회원이 다음 각 호에 해당하는 행위를 하였을 경우 사전통지
          없이 이용계약을 해지하거나 또는 기간을 정하여 서비스 이용을 중지할 수
          있습니다.
          <UnorderedList>
            <CustomList>타인의 서비스 ID 및 비밀번호를 도용한 경우</CustomList>
            <CustomList>서비스 운영을 고의로 방해한 경우</CustomList>
            <CustomList>
              가입신청서에 기재한 회원의 이름이 실명이 아닌 경우
            </CustomList>
            <CustomList>동일 사용자가 다른 ID로 이중등록을 한 경우</CustomList>
            <CustomList>
              공공질서 및 미풍양속에 저해되는 내용을 고의로 유포시킨 경우
            </CustomList>
            <CustomList>
              회원이 국익 또는 사회적 공익을 저해할 목적으로 서비스이용을 계획
              또는 실행하는 경우
            </CustomList>
            <CustomList>
              타인의 명예를 손상시키거나 불이익을 주는 행위를 한 경우
            </CustomList>
            <CustomList>
              다른 회원의 의사에 반하여 광고성 정보를 전송하는 경우
            </CustomList>
            <CustomList>
              정보통신설비 또는 정보 등의 파괴, 변경 기타 장애를 유발시키는
              컴퓨터 바이러스 프로그램 등을 유포하는 경우
            </CustomList>
            <CustomList>회사 기타 타인의 지적재산권을 침해하는 경우</CustomList>
            <CustomList>
              방송통신심의위원회 등 외부기관으로부터 삭제 등 시정요구가 있거나
              선거관리위원회로부터 불법선거운동에 해당한다는 유권해석을 받은
              경우
            </CustomList>
            <CustomList>
              타인의 개인정보, 이용자ID 및 비밀번호를 부정하게 사용하는 경우
            </CustomList>
            <CustomList>
              회사의 서비스 정보를 이용하여 얻은 정보를 회사의 사전 승낙 없이
              복제 또는 유통시키거나 상업적으로 이용하는 경우
            </CustomList>
            <CustomList>
              회원이 음란물을 게재하거나 음란 기타 미풍양속을 해치는 내용의
              사이트로 링크할 수 있게 한 경우
            </CustomList>
            <CustomList>
              본 약관을 포함하여 기타 회사가 정한 이용조건에 위반한 경우
            </CustomList>
          </UnorderedList>
        </p>
        <Article>제16조 (면책조항)</Article>
        <p>
          1. 회사가 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스를
          제공할 수 없게 된 경우에는 서비스 제공에 관한 책임이 면제됩니다.
          <br />
          2. 회사는 회원의 귀책사유로 인한 서비스 이용의 장애에 대하여 책임을
          지지 않습니다.
          <br />
          3. 회사가 제공하는 서비스에 포함된 모든 정보, 소프트웨어, 제품 등에는
          부정확함이나 오류가 있을 수 있습니다. 회사는 서비스를 통해 제공되는
          정보의 부정확함이 중대한 하자 및 회원의 손해로 귀결되지 않는 이상 이에
          대한 책임을 지지 않습니다.
          <br />
          4. 회사는 서비스를 통해 제공된 어떠한 문서나 상담의 내용에 대해서도
          책임을 지지 않으며 그밖에 서비스를 이용함으로써 기대하는 수익을 상실한
          것에 대하여 책임을 지지 않습니다. 그러므로 회원이 법률적 또는 의학적,
          기타 재정적 사항에 관하여 필요한 결정을 하고자 할 때에는 반드시 사전에
          전문가와 상의하여야 합니다.
          <br />
          5. 회사는 회원이 서비스에 게재한 정보, 자료, 사실의 신뢰도, 정확성 등
          내용에 관하여는 책임을 지지 않습니다.
          <br />
          6. 회사는 이용자 상호간 및 이용자와 제3자 간에 서비스를 매개로 발생한
          분쟁에 대해 개입할 의무가 없으며 이로 인한 손해는 배상할 책임을 지지
          않습니다.
        </p>
        <Article>제17조 (고충처리)</Article>
        <p>
          회사는 이메일을 통하여 개인정보와 관련한 회원의 의견을 수렴하고
          불만사항을 접수하여 이를 처리합니다.
        </p>
        <Article>제18조 (분쟁해결 및 관할법원)</Article>
        <p>
          1. 회사 및 회원은 개인정보에 관한 분쟁이 있는 경우 신속하고 효과적인
          분쟁해결을 위하여 개인정보분쟁조정위원회에 분쟁의 조정을 신청할 수
          있습니다.
          <br />
          2. 회사와 회원 간에 서비스 이용으로 발생한 분쟁에 대하여는
          대한민국법을 적용하며, 본 분쟁으로 인하여 소송이 제기될 경우
          민사소송법 상의 관할을 가지는 대한민국의 법원에 제기합니다.
        </p>
        <p>(시행일) 이 약관은 2024년 08월 21일부터 시행합니다.</p>
      </Stack>
    </Container>
  );
}
