import { Container, Heading, Stack, styled, Text } from "@chakra-ui/react";

const Article = styled(Text, {
  baseStyle: {
    fontSize: "16",
    fontWeight: "700",
    my: "3",
  },
});

export default function Privacy() {
  return (
    <Container maxW="3xl">
      <Stack>
        <Heading mb="3">개인정보 처리방침</Heading>
        <Article>
          비셀프(이하 ‘회사’라 한다)는 개인정보 보호법 제30조에 따라 정보주체의
          개인정보를 보호하고 이와 관련한 고충을 신속하고 원활하게 처리할 수
          있도록 하기 위하여 다음과 같이 개인정보 처리방침을 수립․ 공개합니다.
        </Article>
        <Article>
          제1조(개인정보의 처리목적) 회사는 다음의 목적을 위하여 개인정보를
          처리합니다. 처리하고 있는 개인정보는 다음의 목적 이외의 용도로는
          이용되지 않으며, 이용 목적이 변경되는 경우에는 개인정보 보호법
          제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.
        </Article>
        <p>
          1. 회원 가입 및 관리
          <br />- 회원 가입의사 확인, 회원제 서비스 제공에 따른 본인 식별․인증,
          회원자격 유지․관리, 제한적 본인확인제 시행에 따른 본인확인, 서비스
          부정이용 방지, 만 14세 미만 아동의 개인정보 처리시 법정대리인의
          동의여부 확인, 각종 고지․통지, 고충처리 등을 목적으로 개인정보를
          처리합니다.
          <br />
          2. 재화 또는 서비스 제공
          <br />- 서비스 제공, 콘텐츠 제공, 맞춤서비스 제공, 본인인증 등을
          목적으로 개인정보를 처리합니다.
        </p>
        <Article>제2조(처리하는 개인정보의 항목 및 보유기간)</Article>
        <p>
          회사는 다음의 개인정보 항목을 처리합니다.
          <br />
          1. 회원 가입 및 관리 관련
          <br />- [필수] 성명, 이메일, 비밀번호
          <br />
          2. 재화 또는 서비스 제공 관련
          <br />- [필수]성명, 이메일, 비밀번호
          <br />
          3. 선택항목
          <br />- 생년월일, 성별
          <br />
          4. 서비스 이용과정에서 아래 개인정보 항목이 자동으로 생성되어 수집될
          수 있습니다.
          <br />- 서비스 이용기록, 방문기록, 불량 이용기록 등
        </p>
        <Article>제3조(개인정보의 처리 및 보유기간)</Article>
        <p>
          회사는 정보주체로부터 개인정보를 수집시에 동의받은 개인정보 보유/이용
          기간 내에서 개인정보를 처리 및 보유하며, 원칙적으로 개인정보의
          처리목적이 달성되면 지체 없이 파기합니다. 단 관계 법령 위반에 따른
          수사‧조사 등이 진행중인 경우에는 해당 수사‧조사 종료시까지 개인정보를
          처리 및 보유합니다.
        </p>
        <Article>제4조(개인정보의 제3자 제공)</Article>
        <p>
          ① 회사는 정보주체의 개인정보를 제1조(개인정보의 처리 목적)에서 명시한
          범위 내에서만 처리하며, 정보주체의 동의, 법률의 특별한 규정 등
          개인정보 보호법 제17조에 해당하는 경우에만 개인정보를 제3자에게
          제공합니다.
        </p>
        <Article>제 5조(개인 정보 정정 및 삭제 문의)</Article>
        <p>
          : 개인정보보호 부서 이메일(beself365@gmail.com)로 연락주시면 바로
          처리해드리겠습니다.
        </p>
        <Article>제 6조(만 14세 미만 아동의 개인정보수집 및 이용 관련)</Article>
        <p>
          : 만 14세 미만 아동이 본 서비스를 이용하고자 할 경우, 법정대리인의
          동의서를 받아서 개인정보보호 부서 이메일(beself365@gmail.com)로
          송부하여 주시기 바랍니다. 회사는 법정대리인의 동의를 받지 않은 만 14세
          미만 아동의 가입이 확인될 경우 해당 아동의 계정 등 정보를 삭제할 수
          있습니다.
        </p>
        <Article>제7조(정보주체의 권리‧의무 및 행사방법)</Article>
        <p>
          ① 정보주체는 회사에 대해 언제든지 다음 각 호의 개인정보 보호 관련
          권리를 행사할 수 있습니다.
          <br />
          1. 개인정보 열람요구
          <br />
          2. 오류 등이 있을 경우 정정 요구
          <br />
          3. 삭제요구
          <br />
          4. 처리정지 요구
          <br />② 제1항에 따른 권리 행사는 회사에 대해 전자우편을 통하여 하실 수
          있으며 회사는 이에 대해 지체없이 조치하겠습니다.
          <br />③ 정보주체가 개인정보의 오류 등에 대한 정정 또는 삭제를 요구한
          경우에는 회사는 정정 또는 삭제를 완료할 때까지 당해 개인정보를
          이용하거나 제공하지 않습니다.
          <br />④ 제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은
          자 등 대리인을 통하여 하실 수 있습니다. 이 경우 개인정보 보호법
          시행규칙 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.
          <br />⑤ 정보주체는 개인정보 보호법 등 관계법령을 위반하여 회사가
          처리하고 있는 정보주체 본인이나 타인의 개인정보 및 사생활을
          침해하여서는 아니됩니다.
        </p>
        <Article>제8조(개인정보의 파기)</Article>
        <p>
          ① 회사는 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가
          불필요하게 되었을 때에는 지체없이 해당 개인정보를 파기합니다.
          <br />② 정보주체로부터 동의받은 개인정보 보유기간이 경과하거나
          처리목적이 달성되었음에도 불구하고 다른 법령에 따라 개인정보를 계속
          보존하여야 하는 경우에는, 해당 개인정보를 별도의 데이터베이스(DB)로
          옮기거나 보관장소를 달리하여 보존합니다.
          <br />③ 개인정보 파기의 절차 및 방법은 다음과 같습니다.
          <br />
          1. 파기절차
          <br />
          회사는 파기 사유가 발생한 개인정보를 선정하고, 회사의 개인정보
          보호책임자의 승인을 받아 개인정보를 파기합니다.
          <br />
          2. 파기방법
          <br />
          회사는 전자적 파일 형태로 기록․저장된 개인정보는 기록을 재생할 수
          없도록 로우레밸포멧(Low Level Format) 등의 방법을 이용하여 파기하며,
          종이 문서에 기록‧저장된 개인정보는 분쇄기로 분쇄하거나 소각하여
          파기합니다.
        </p>
        <Article>
          제9조(개인정보의 안전성 확보조치) 회사는 개인정보의 안전성 확보를 위해
          다음과 같은 조치를 취하고 있습니다.
        </Article>
        <p>
          회사는 개인정보의 안전한 보호를 위해 다양한 기술적, 관리적 대책을
          마련하고 있습니다.
        </p>
        <Article>제10조(개인정보관리책임자)</Article>
        <p>
          담당자 : 김형준
          <br />
          연락처 : beself365@gmail.com
        </p>
        <Article>
          제11조(개인정보 열람청구) 정보주체는 개인정보 보호법 제35조에 따른
          개인정보의 열람 청구를 회사에 할 수 있습니다. 회사는 정보주체의
          개인정보 열람청구가 신속하게 처리되도록 노력하겠습니다.
        </Article>
        <Article>
          제12조(권익침해 구제방법) 정보주체는 아래의 기관에 대해 개인정보
          침해에 대한 피해구제, 상담 등을 문의하실 수 있습니다.
        </Article>
        <p>
          아래의 기관은 회사와는 별개의 기관으로서, 회사의 자체적인 개인정보
          불만처리, 피해구제 결과에 만족하지 못하시거나 보다 자세한 도움이
          필요하시면 문의하여 주시기 바랍니다.
          <br />▶ 개인정보 침해신고센터 (한국인터넷진흥원 운영)
          <br />- 소관업무 : 개인정보 침해사실 신고, 상담 신청
          <br />- 홈페이지 : privacy.kisa.or.kr
          <br />- 전화 : (국번없이) 118
          <br />- 주소 : (58324) 전남 나주시 진흥길 9(빛가람동 301-2) 3층
          <br />
          개인정보침해신고센터
          <br />▶ 개인정보 분쟁조정위원회
          <br />- 소관업무 : 개인정보 분쟁조정신청, 집단분쟁조정 (민사적 해결)
          <br />- 홈페이지 : www.kopico.go.kr
          <br />- 전화 : (국번없이) 1833-6972
          <br />- 주소 : (03171)서울특별시 종로구 세종대로 209 정부서울청사 4층
          <br />▶ 대검찰청 사이버범죄수사단 : 02-3480-3573 (www.spo.go.kr)
          <br />▶ 경찰청 사이버안전국 : 182 (http://cyberbureau.police.go.kr)
        </p>
        <Article>제13조(개인정보 처리방침의 변경)</Article>
        <p>
          현 개인정보처리방침의 내용 추가, 삭제 및 수정이 있을 시에는 개정 최소
          7일전부터 이메일을 통해 고지할 것입니다. 다만, 개인정보의 수집 및
          활용, 제3자 제공 등과 같이 이용자 권리의 중요한 변경이 있을 경우에는
          최소 15일전에 고지합니다.
        </p>
        <p>본 개인정보취급방침은 2024년 08월 21일부터 시행됩니다.</p>
      </Stack>
    </Container>
  );
}
